import React from 'react'
import { useDispatch } from 'react-redux'
import { fetchMartinis } from '../store/martinis'

export default ({ data }) => {
  const dispatch = useDispatch()

  return (
    <div className="px-3 py-12 text-xl text-center">
      <p className="mb-6">
        There was a problem loading these delicious martinis.
      </p>
      <button
        onClick={() => dispatch(fetchMartinis())}
        className="px-4 py-2 text-white bg-gray-900 rounded-md font-display hover:bg-gray-800 transition"
      >
        Try Again
      </button>
    </div>
  )
}
