import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchMartinis } from '../store/martinis'
import Header from '../components/Header'
import Martinis from '../views/Martinis'
import Martini from '../views/Martini'
import '../styles/app.css'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMartinis())
  }, [dispatch])

  return (
    <Router>
      <div className="font-serif App">
        <Header />
        <Switch>
          <Route path="/:uid" component={Martini} />
          <Route path="/" component={Martinis} exact />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
