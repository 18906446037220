import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { gradientStyles, textStyles } from '../utilities/styles'
import Loading from '../components/Loading'
import Error from '../components/Error'

const Martini = ({ data, uid, index }) => {
  const smallClassName = index === 3 || index === 8 ? 'col-start-2' : ''

  return (
    <Link
      className={[
        smallClassName,
        'relative h-0 pt-100 col-span-2 md:col-span-1 row-span-2 md:row-span-2',
      ].join(' ')}
      to={uid}
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="relative flex items-center justify-center w-full h-full">
          <div
            className="absolute w-1/2 bg-gray-200 rounded-full h-1/2 md:w-1/4 md:h-1/4"
            style={gradientStyles(data.color_one, data.color_two)}
          />
          <div
            className="relative text-2xl font-bold text-gray-800 font-display sm:text-3xl lg:text-4xl"
            style={textStyles(data.color_text)}
          >
            {data.name}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default () => {
  const [redirect, setRedirect] = useState(null)
  const { data, isLoading, error } = useSelector((state) => state.martinis)

  // key events
  const handleKeyUp = useCallback(
    (event) => {
      const { keyCode } = event
      if (keyCode === 37) {
        // Left
        setRedirect(data[data.length - 1].uid)
      } else if (keyCode === 39) {
        // Right
        setRedirect(data[0].uid)
      }
    },
    [data]
  )

  // eventListener hook
  useEffect(() => {
    document.addEventListener('keydown', handleKeyUp, false)
    return () => {
      document.removeEventListener('keydown', handleKeyUp, false)
    }
  }, [handleKeyUp])

  return (
    <Fragment>
      {error && !isLoading ? (
        <Error data={error} />
      ) : data.length && !error ? (
        <div className="w-full max-w-6xl py-3 mx-auto grid grid-cols-6 md:grid-cols-4 lg:col-gap-12">
          {data.map((martini, index) => (
            <Martini
              key={martini.id}
              data={martini.data}
              uid={martini.uid}
              index={index}
            />
          ))}
          {redirect && <Redirect to={redirect} />}
        </div>
      ) : (
        <Loading />
      )}
    </Fragment>
  )
}
