import prismic from 'prismic-javascript'

const endpoint = 'https://lilismartinis.cdn.prismic.io/api/v2'
const accessToken =
  'MC5Yc0xjZ2hFQUFJckU4WVlE.ESPvv71ZQu-_ve-_ve-_ve-_vRYxNe-_ve-_vQrvv73vv718NO-_vXbvv70p77-9du-_ve-_ve-_vTAMbgk'

const api = prismic.client(endpoint, { accessToken })

export const getMartinis = async () => {
  try {
    const martinis = await api.query(
      prismic.Predicates.at('document.type', 'martini'),
      {
        orderings: '[my.martini.name]',
      }
    )

    return martinis
  } catch (err) {
    throw err
  }
}
