import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import { find, findIndex } from 'lodash'
import { gradientStyles, textStyles } from '../utilities/styles'
import Loading from '../components/Loading'
import Error from '../components/Error'

const Martini = ({ data }) => (
  <div className="pt-3 text-gray-800" style={textStyles(data.color_text)}>
    <div className="flex items-center justify-center py-6 text-center md:py-12">
      <div
        className="absolute w-16 h-16 bg-gray-200 rounded-full md:w-20 md:h-20"
        style={gradientStyles(data.color_one, data.color_two)}
      />
      <div className="relative text-5xl font-bold lg:text-6xl font-display">
        {data.name}
      </div>
    </div>
    <div className="relative block px-3 text-2xl text-center leading-7">
      <RichText render={data.recipe} />
    </div>
  </div>
)

export default () => {
  const [redirect, setRedirect] = useState(null)
  const { data, isLoading, error } = useSelector((state) => state.martinis)

  // uid
  const { uid } = useParams()
  const martini = find(data, ['uid', uid])

  // key events
  const handleKeyUp = useCallback(
    (event) => {
      let index = findIndex(data, ['uid', uid])
      const { keyCode } = event
      if (keyCode === 37) {
        // Left
        if (index === 0) index = data.length
        setRedirect(data[index - 1].uid)
      } else if (keyCode === 39) {
        // Right
        if (index === data.length - 1) index = -1
        setRedirect(data[index + 1].uid)
      } else if (keyCode === 27) {
        // Esc
        setRedirect('/')
      }
    },
    [data, uid]
  )

  // eventListener hook
  useEffect(() => {
    document.addEventListener('keydown', handleKeyUp, false)
    return () => {
      document.removeEventListener('keydown', handleKeyUp, false)
    }
  }, [handleKeyUp])

  return (
    <Fragment>
      {error && !isLoading ? (
        <Error data={error} />
      ) : martini && !error ? (
        <Fragment>
          <Martini data={martini.data} />
          {redirect && <Redirect to={redirect} />}
        </Fragment>
      ) : (
        <Loading />
      )}
    </Fragment>
  )
}
