import { createSlice } from '@reduxjs/toolkit'
import { getMartinis } from '../utilities/api'

export const martinisSlice = createSlice({
  name: 'martinis',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    getMartinisStart: (state) => {
      state.isLoading = true
    },
    getMartinisFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getMartinisSuccess: (state, { payload }) => {
      state.isLoading = false
      state.error = null
      state.data = payload.results
    },
  },
})

export const {
  getMartinisStart,
  getMartinisFailure,
  getMartinisSuccess,
} = martinisSlice.actions

export const fetchMartinis = () => async (dispatch) => {
  try {
    dispatch(getMartinisStart())
    const martinis = await getMartinis()
    dispatch(getMartinisSuccess(martinis))
  } catch (error) {
    dispatch(getMartinisFailure(error.toString()))
  }
}

export default martinisSlice.reducer
