import React from 'react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className="relative z-10 flex items-center justify-center w-full py-1 bg-white border-b border-gray-300 lg:py-3">
      <Link
        to="/"
        className="text-3xl font-extrabold text-gray-900 lg:text-4xl font-display"
      >
        lili's martinis
      </Link>
    </div>
  )
}
